import React from "react"
// import {
//   graphql,
//   useStaticQuery,
// } from "gatsby"

// Media Imports
import header from '../../images/projects/safesend-header.png'

// Component Imports
import Layout from "../../layout"
import ResumeCTA from "../../components/resume-cta"

const SafeSend = () => {
  // const data = useStaticQuery(
  //   graphql`
  //   query {
  //   }
  // `)

  return (
    <Layout>


      <section class="project-hero" style={{ backgroundImage: `url(${header})` }}>
      </section>


      <section class="project-intro">
        <div class="sw">
          <div class="content">
            <h1>SafeSend Website Project</h1>
            <div class="body">
              <div class="left">
                <div class="item">
                  <h3>Company</h3>
                  <ul>
                    <li>SafeSend | <a href="https://www.safesend.com" target="_blank" rel="noreferrer">safesend.com</a></li>
                  </ul>
                </div>
                <div class="item">
                  <h3>Date</h3>
                  <ul>
                    <li>September 2020 - December 2020</li>
                  </ul>
                </div>
                <div class="item">
                  <h3>Role</h3>
                  <ul>
                    <li>UX Designer</li>
                    <li>UX Researcher</li>
                  </ul>
                </div>
                <div class="item">
                  <h3>Responsibilities</h3>
                  <ul>
                    <li>Lo-Fidelity Wireframing</li>
                    <li>Quality Assurance Testing</li>
                    <li>Client Communication</li>
                  </ul>
                </div>
                <div class="item item--last">
                  <h3>Technologies Used</h3>
                  <ul>
                    <li>Adobe Xd</li>
                    <li>Adobe Photoshop</li>
                  </ul>
                </div>
              </div>
              <div class="right"><div class="item">
                <h2>Overview</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eleifend, mi vitae finibus fermentum, urna ipsum consectetur nulla, a feugiat mauris nibh vel justo. Sed maximus maximus lorem a vulputate. Vivamus malesuada dapibus est, a pharetra nisi sodales eget. Aliquam rutrum sapien vitae elit consequat rutrum. Sed luctus arcu maximus, imperdiet arcu a, interdum libero. Curabitur tincidunt semper nunc eget accumsan. Praesent gravida laoreet pretium. Nulla tristique faucibus sapien, non maximus nibh gravida vel. In mollis rutrum commodo. Aliquam molestie aliquam felis, eu consequat ex. Vestibulum scelerisque blandit tellus imperdiet faucibus. Nam lobortis egestas tellus. Sed venenatis accumsan risus vel molestie.</p>
              </div>
                <div class="item">
                  <h2>Problem</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eleifend, mi vitae finibus fermentum, urna ipsum consectetur nulla, a feugiat mauris nibh vel justo. Sed maximus maximus lorem a vulputate. Vivamus malesuada dapibus est, a pharetra nisi sodales eget. Aliquam rutrum sapien vitae elit consequat rutrum. Sed luctus arcu maximus, imperdiet arcu a, interdum libero. Curabitur tincidunt semper nunc eget accumsan. Praesent gravida laoreet pretium. Nulla tristique faucibus sapien, non maximus nibh gravida vel. In mollis rutrum commodo. Aliquam molestie aliquam felis, eu consequat ex. Vestibulum scelerisque blandit tellus imperdiet faucibus. Nam lobortis egestas tellus. Sed venenatis accumsan risus vel molestie.</p>
                </div>
                <div class="item">
                  <h2>Solution</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eleifend, mi vitae finibus fermentum, urna ipsum consectetur nulla, a feugiat mauris nibh vel justo.</p>
                </div>
                <div class="item item--goals">
                  <h2>Goals</h2>
                  <ol class="list">
                    <li><strong>1.</strong> Create a brand new Puente website featuring a modern and user-friendly design based on current best practices and a revamped set of company branding guidelines. </li>
                    <li><strong>2.</strong> Showcase technology that Puente creates to aid their community in a way that is fitting of a modern technology company. </li>
                    <li><strong>3.</strong> Showcase Puente’s community projects. </li>
                    <li><strong>4.</strong> Showcase featured members of Puente’s robust volunteer network, and offer ways for new volunteers to get involved. </li>
                    <li><strong>5.</strong> Enable users to stay connected with Puente on projects, technology, and other news items that Puente wishes to advertise. </li>
                    <li><strong>6.</strong> Enable users to easily support Puente through donating from the website or purchasing merchandise. </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <ResumeCTA />


    </Layout>
  )
}

export default SafeSend